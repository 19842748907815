import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function QuizPage() {
  const navigate = useNavigate();
  const [score, setScore] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let score = 0;

    if (formData.get('q1') === 'correct') score++;
    if (formData.get('q2') === 'correct') score++;

    setScore(score);
    navigate('/result', { state: { score } });
  };

  return (
    <div className="container">
      <h2>Quick Math Quiz</h2>
      <form onSubmit={handleSubmit}>
        <div className="question">
          <label>Q1: 5 + 3 = ?</label>
          <div>
            <label><input type="radio" name="q1" value="wrong" /> 6</label>
            <label><input type="radio" name="q1" value="correct" /> 8</label>
            <label><input type="radio" name="q1" value="wrong" /> 9</label>
          </div>
        </div>
        <div className="question">
          <label>Q2: 7 - 4 = ?</label>
          <div>
            <label><input type="radio" name="q2" value="correct" /> 3</label>
            <label><input type="radio" name="q2" value="wrong" /> 5</label>
            <label><input type="radio" name="q2" value="wrong" /> 2</label>
          </div>
        </div>
        <button type="submit" className="btn-submit">Submit</button>
      </form>
    </div>
  );
}

export default QuizPage;
