import React from 'react';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const navigate = useNavigate();

  const handleBeginTest = () => {
    navigate('/quiz');
  };

  return (
    <div className="container">
      <header>
        <h1>LearningApp</h1>
      </header>

      <section className="content">
        <h2>Start Your Learning Journey</h2>
        <p>Answer a few questions to assess your skill level and receive personalized learning suggestions.</p>
        <button className="btn-start" onClick={handleBeginTest}>Begin Test</button>
      </section>

      <footer>
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
