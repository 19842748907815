import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function ResultPage() {
  const location = useLocation();
  const { score } = location.state || { score: 0 };

  return (
    <div className="container">
      <h2>Your Quiz Result</h2>
      <p>Your score is: {score} / 2</p>
      <Link to="/" className="btn-start">Go Back to Home</Link>
    </div>
  );
}

export default ResultPage;
